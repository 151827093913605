export const BG_PRIMARY = '#FDF6E4';
export const BG_SECONDARY = '#A38467';
export const BG_ALTERNATIVE = '#A38467';
export const BG_OPACITY = '#A38467';

export const TEXT_PRIMARY = '#A38467';
export const TEXT_SECONDARY = '#fff';
export const TEXT_PRIMARY_DARK = '#A38467';

export const NAVIGATION_COLOR = '#614f3d';
export const ICON_NAVIGATION_COLOR = '#FFFFFF';
